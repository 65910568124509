import { useEffect, useState } from 'react';
import { useGetAwaitingClientSignContent } from 'src/components/AwaitingClientSign/contentstack';

import { ComponentHookResult, ContentOptions, findFieldValue } from '@sigfig/digital-wealth-core';

export interface AwaitingClientSignContent {
  heading: string;
  imageUrl: string;
  paragraphOne: string;
  paragraphTwo: string;
  primaryButtonCta: string;
  rcePrimaryButtonCta: string;
}

export const useAwaitingClientSign = (
  contentOptions: ContentOptions,
): ComponentHookResult<AwaitingClientSignContent> => {
  const [state, setState] = useState<ComponentHookResult<AwaitingClientSignContent>>({
    loading: true,
  });

  const {
    data: contentstackData,
    loading: contentstackLoading,
    error: contentstackError,
  } = useGetAwaitingClientSignContent({
    variables: contentOptions,
  });

  useEffect(() => {
    if (contentstackLoading) {
      setState({ loading: contentstackLoading });
      return;
    }

    if (contentstackError) {
      setState({ loading: false, error: contentstackError });
      return;
    }

    const content = contentstackData?.all_nyl_docusign?.items?.[0]?.awaiting_client_sign;
    const labels = contentstackData?.all_nyl_docusign?.items?.[0]?.labels ?? [];

    const nonPrimaryAdvUploadContentData = {
      heading: content?.header_text ?? '',
      imageUrl: content?.header_imageConnection?.edges?.[0]?.node?.url ?? '',
      paragraphOne: content?.paragraph_one ?? '',
      paragraphTwo: content?.paragraph_two ?? '',
      primaryButtonCta: content?.primary_button_cta ?? '',
      rcePrimaryButtonCta: findFieldValue(labels, 'rcePrimaryButtonCta'),
    };
    setState({ data: nonPrimaryAdvUploadContentData, loading: false });
  }, [contentstackData?.all_nyl_docusign?.items, contentstackError, contentstackLoading]);

  return state;
};
