import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HelpIcon from '@mui/icons-material/Help';
import ListIcon from '@mui/icons-material/List';
import Menu from '@mui/icons-material/Menu';
import RefreshIcon from '@mui/icons-material/Refresh';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Divider from '@mui/material/Divider';
import React, { useState } from 'react';

import {
  Box,
  Button,
  Drawer,
  getFullName,
  Grid,
  RteContent,
  Tooltip,
  Typography,
  useCoreConfig,
  useGetClientInfo,
  useIsMediumScreen,
} from '@sigfig/digital-wealth-core';

import { PartnerConfig } from '../../config';

import { useGetHeaderContent } from './contentstack';

interface Props {
  dataQa?: string;
  onBack?: () => void;
  onHeaderLogoClick?: () => void;
  onRefreshButtonClick?: () => void;
  partyIdClient?: string;
  showAdvisorButton?: boolean;
  showBackToAccountDetails?: boolean;
  showBackToAccountSummary?: boolean;
  showBackToViewClientList?: boolean;
  showPerformanceTitle?: boolean;
  showRefreshButton?: boolean;
  showSaveAndExit?: boolean;
  showSubHeader?: boolean;
}

export const Header: React.FC<Props> = ({
  dataQa = 'header',
  partyIdClient,
  showAdvisorButton = true,
  showBackToAccountSummary = false,
  showBackToAccountDetails = false,
  showBackToViewClientList = false,
  showSaveAndExit = false,
  showSubHeader,
  onBack,
  onHeaderLogoClick,
  onRefreshButtonClick,
  showRefreshButton,
  showPerformanceTitle = false,
}): any => {
  const { contentOptions } = useCoreConfig<PartnerConfig>();
  const isMobile = useIsMediumScreen();
  const [open, setOpen] = useState(false);
  const { data: headerComponentData } = useGetHeaderContent({
    variables: contentOptions,
  });
  const content = headerComponentData?.all_header?.items?.[0];
  const {
    data: clientNameData,
    loading: clientNameLoading,
    error: clientNameError,
  } = useGetClientInfo({
    skip: !partyIdClient,
    variables: { partyId: partyIdClient ?? '' },
  });
  const partyPerson = clientNameData?.client?.party?.partyPerson;
  const clientName = getFullName(partyPerson) ?? undefined;

  const getMenuActions = () => (
    <Grid
      alignItems={isMobile ? 'left' : 'center'}
      container
      flexDirection={isMobile ? 'column' : 'row'}
      height="100%"
      justifyContent="right"
    >
      {showRefreshButton && onRefreshButtonClick && content?.refresh_button_text && (
        <Grid item>
          <Button onClick={onRefreshButtonClick} startIcon={<RefreshIcon />} sx={{ mr: 2, color: 'text.primary' }}>
            <Typography component="span" variant="subtitle2">
              {content.refresh_button_text}
            </Typography>
          </Button>
        </Grid>
      )}
      {content?.help_tooltip_content && (
        <Grid item sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tooltip
            tooltipContent={
              <>
                <Typography sx={{ fontWeight: 700 }} variant="inherit">
                  {content.help_tooltip_header}
                </Typography>
                <RteContent data={content.help_tooltip_content} />
              </>
            }
          >
            <Button startIcon={<HelpIcon />} sx={{ color: 'text.primary' }}>
              <Typography component="span" mr={4} variant="subtitle2">
                {content.help_button_text}
              </Typography>
            </Button>
          </Tooltip>
        </Grid>
      )}
      {showSaveAndExit && (
        <Grid item sx={{ mr: 2 }}>
          <Button onClick={onBack} sx={{ color: 'primary.main', border: '1px solid' }}>
            <Typography variant="body1">{content?.save_exit_text}</Typography>
          </Button>
        </Grid>
      )}
      {!showSaveAndExit && showAdvisorButton && (
        <Grid
          item
          sx={{
            background: 'linear-gradient(0deg, rgba(35, 101, 246, 0.08), rgba(35, 101, 246, 0.08)), #FFFFFF',
            color: 'primary.main',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            px: 4,
          }}
        >
          <SupervisedUserCircleIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="subtitle2">{content?.advisor_text}</Typography>
        </Grid>
      )}
    </Grid>
  );

  const headerStyle = {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  };
  return (
    <>
      <Grid container data-qa={dataQa} sx={{ ...headerStyle, height: '80px' }}>
        <Grid item pl={4} xs={!isMobile ? 3 : 5}>
          {content?.logoConnection?.edges?.[0]?.node?.url && onHeaderLogoClick && (
            <Button onClick={onHeaderLogoClick} sx={{ justifyContent: 'flex-start' }}>
              <Box component="img" src={content.logoConnection.edges[0].node.url} />
            </Button>
          )}
        </Grid>

        {showPerformanceTitle && (
          <Grid item textAlign="center" xs={6}>
            <Typography sx={{ color: 'text.primary', fontWeight: 700 }} variant="subtitle1">
              {content?.performance_title}
            </Typography>
          </Grid>
        )}

        {isMobile && (
          <Grid data-qa={`${dataQa}-actions`} item textAlign="right" xs>
            <Button
              aria-label="Menu actions"
              data-qa={`${dataQa}-menu-mobile`}
              onClick={() => setOpen(true)}
              sx={{ color: 'text.primary' }}
            >
              <Menu />
            </Button>
            <Drawer
              actions={<Box sx={{ display: 'flex', flexDirection: 'column' }}>{getMenuActions()}</Box>}
              onClose={() => setOpen(false)}
              open={open}
            />
          </Grid>
        )}

        {!isMobile && (
          <Grid height="100%" item xs={showSaveAndExit || showPerformanceTitle ? 3 : 12}>
            {getMenuActions()}
          </Grid>
        )}
      </Grid>
      {(showBackToAccountSummary || showBackToAccountDetails || showBackToViewClientList) && onBack && (
        <Grid
          data-qa={dataQa}
          item
          sx={{
            // TODO: Remove bottom border when the account details page has grey background in the top section
            borderBottom: showBackToAccountSummary ? '1px solid rgba(0, 0, 0, 0.12)' : '',
            px: 3,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            height: '45px',
          }}
        >
          <Box sx={{ display: 'flex', width: 1 }}>
            <Button
              onClick={onBack}
              startIcon={<ChevronLeftIcon fontSize="small" sx={{ color: 'primary.main' }} />}
              sx={{ pr: 2 }}
            >
              <Typography sx={{ color: 'text.primary', fontWeight: 400 }} variant="body2">
                {showBackToViewClientList
                  ? content?.back_to_fa_dashboard_button_text
                  : showBackToAccountSummary
                  ? content?.back_to_account_summary_button_text
                  : content?.back_to_account_details_button_text}
              </Typography>
            </Button>
          </Box>
        </Grid>
      )}
      {showSubHeader && !clientNameLoading && !clientNameError && clientName && (
        <Grid
          data-qa={`${dataQa}-subheader`}
          item
          sx={{
            py: 2,
            backgroundColor: 'action.background',
            color: 'primary.main',
          }}
          xs={12}
        >
          <RteContent
            config={{ clientName }}
            data={content?.subheader_text ?? ''}
            sx={{ textAlign: 'center' }}
            variantMapping={{ h6: 'div' }}
          />
        </Grid>
      )}
    </>
  );
};
