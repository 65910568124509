import { loader } from 'graphql.macro';

import {
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult,
  useSymphonyMutation,
  useSymphonyQuery,
} from '@sigfig/digital-wealth-core';

import { CreateSigningDocument, CreateSigningDocumentVariables } from './__generated__/CreateSigningDocument';
import {
  GetDocusignStatus,
  GetDocusignStatus_managedProduct_legalDocuments,
  GetDocusignStatus_managedProduct_legalDocuments_signees,
  GetDocusignStatusVariables,
} from './__generated__/GetDocusignStatus';

const getGetDocusignStatusQuery = loader('./GetDocusignStatus.gql');
const createSigningDocumentMutation = loader('./CreateSigningDocument.gql');

export type ManagedProductLegalDocument = GetDocusignStatus_managedProduct_legalDocuments;
export type ManagedProductLegalDocumentSignee = GetDocusignStatus_managedProduct_legalDocuments_signees;

export const useGetDocusignStatus = (
  options?: QueryHookOptions<GetDocusignStatus, GetDocusignStatusVariables>,
): QueryResult<GetDocusignStatus, GetDocusignStatusVariables> => {
  return useSymphonyQuery(getGetDocusignStatusQuery, options);
};

export const useCreateSigningDocument = (
  options?: MutationHookOptions<CreateSigningDocument, CreateSigningDocumentVariables>,
): MutationTuple<CreateSigningDocument, CreateSigningDocumentVariables> => {
  return useSymphonyMutation(createSigningDocumentMutation, options);
};
