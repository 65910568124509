import {
  FADashboardTabs,
  LegalDocumentStatus,
  OpsDashboardParams,
  OpsDashboardParamsV2,
} from '@sigfig/digital-wealth-core';
import { DocusignUserType } from '@sigfig/digital-wealth-core/dist/commonjs/containers/DocusignDAProCompleted';

import { generateAbsolutePath, getSearchParams } from './utils/routes';

export enum PageRoute {
  AccountDetails = 'account-summary/:partyId/details/:managedProductId',
  AccountPerformance = 'account-summary/:partyId/performance/:managedProductId',
  AccountProfile = 'account-profile/:partyId/:managedProductId',
  AccountProfileDocusignComplete = 'docusignComplete/:partyId/account-profile/:managedProductId',
  AccountSummary = 'account-summary/:partyId',
  DigitalWealth = 'account-summary/:partyId/*',
  DigitalWealthAccountDetails = 'details/:managedProductId',
  DigitalWealthAccountPerformance = 'performance/:managedProductId',
  Docusign = 'docusign/:partyId/:managedProductId/:entityId?',
  DocusignComplete = 'docusignComplete/:partyId/:managedProductId/:bankAccountAssociationId',
  FADashboard = 'fa-dashboard/:partyId',
  OldOpsDashboard = 'ops-dashboard/v1/:partyId/:tab?',
  OpsDashboard = 'ops-dashboard/:partyId/:tab?',
  OpsDashboardSearch = 'ops-dashboard/search',
  RceDocusignCompletedAuth = 'docusignCompleteAuth/:partyId/:managedProductId/:bankAccountAssociationId',
}

export const routes = {
  accountDetails: (partyId: string, managedProductId: string): string =>
    generateAbsolutePath(PageRoute.AccountDetails, { partyId, managedProductId }),
  accountPerformance: (partyId: string, managedProductId: string, source: string): string =>
    generateAbsolutePath(PageRoute.AccountPerformance, { partyId, managedProductId }) + getSearchParams({ source }),
  accountProfile: (partyId: string, managedProductId: string, source: string) =>
    generateAbsolutePath(PageRoute.AccountProfile, { partyId, managedProductId }) + getSearchParams({ source }),
  accountSummary: (partyId: string): string => generateAbsolutePath(PageRoute.AccountSummary, { partyId }),
  docusign: (partyId: string, managedProductId: string | null, entityId: string | null) =>
    generateAbsolutePath(PageRoute.Docusign, { partyId, managedProductId, entityId }),
  docusignComplete: (
    partyId: string,
    managedProductId: string,
    bankAccountAssociationId: string,
    status?: LegalDocumentStatus,
    userType?: DocusignUserType,
  ) =>
    generateAbsolutePath(PageRoute.DocusignComplete, { partyId, managedProductId, bankAccountAssociationId }) +
    getSearchParams({ status, userType }),
  faDashboard: (partyId: string, tab?: FADashboardTabs) =>
    generateAbsolutePath(PageRoute.FADashboard, { partyId }) + (tab ? getSearchParams({ tab }) : ''),
  opsDashboard: (partyId: string, params?: OpsDashboardParamsV2): string =>
    generateAbsolutePath(PageRoute.OpsDashboard, { partyId, tab: params?.tab ?? null }) +
    getSearchParams({ state: params?.state }),
  oldOpsDashboard: (partyId: string, params?: OpsDashboardParams): string =>
    generateAbsolutePath(PageRoute.OldOpsDashboard, { partyId, tab: params?.tab ?? null }) +
    getSearchParams({ state: params?.state }),
  opsDashboardSearch: (): string => generateAbsolutePath(PageRoute.OpsDashboardSearch),
  rceDocusignCompletedAuth: (
    partyId: string,
    managedProductId: string,
    bankAccountAssociationId: string,
    status?: LegalDocumentStatus,
    userType?: DocusignUserType,
    financialAccountVerificationStatus?: string,
  ) =>
    generateAbsolutePath(PageRoute.RceDocusignCompletedAuth, { partyId, managedProductId, bankAccountAssociationId }) +
    getSearchParams({ status, userType, financialAccountVerificationStatus }),
};
