import {
  AssetAllocationTableColumns,
  ContentOptions,
  CoreConfig,
  defaultCoreConfig,
  FinancialAccountType,
  Locale,
  ManagedProductType,
  Partner,
  Product,
} from '@sigfig/digital-wealth-core';

import { getBaseUrl, getSymphonyPath } from '../utils';

export interface PartnerConfig extends CoreConfig {
  contentOptions: ContentOptions;
  defaultProductType: ManagedProductType;
}

const iqa1Url = 'https://iqa1-nyl-ops-dashboard.qa.sigfig.com';
const sqa1Url = 'https://sqa1-nyl-ops-dashboard.qa.sigfig.com';
const sqa2Url = 'https://sqa2-nyl-ops-dashboard.qa.sigfig.com';
const stgUrl = 'https://stg-nyl-ops-dashboard.sigfig.com';
const prodUrl = 'https://nyl-ops-dashboard.sigfig.com';

const isIqa1 = window.location.origin.toLowerCase() === iqa1Url;
const isSqa1 = window.location.origin.toLowerCase() === sqa1Url;
const isSqa2 = window.location.origin.toLowerCase() === sqa2Url;
const isStg = window.location.origin.toLowerCase() === stgUrl;
const isProd = window.location.origin.toLowerCase() === prodUrl;

const getConfigValue = ({
  iqa1,
  sqa1,
  sqa2,
  stg,
  prod,
  _,
}: {
  _: string;
  iqa1?: string;
  prod?: string;
  sqa1?: string;
  sqa2?: string;
  stg?: string;
}) => {
  if (isIqa1 && iqa1) {
    return iqa1;
  }
  if (isSqa1 && sqa1) {
    return sqa1;
  }
  if (isSqa2 && sqa2) {
    return sqa2;
  }
  if (isStg && stg) {
    return stg;
  }
  if (isProd && prod) {
    return prod;
  }
  return _;
};

const clientRceBaseUrl = getConfigValue({
  iqa1: 'https://dw-iqa1-nyl.qa.sigfig.com',
  sqa1: 'https://dw-sqa1-nyl.qa.sigfig.com',
  sqa2: 'https://dw-sqa2-nyl.qa.sigfig.com',
  stg: 'https://dw-stg-nyl.qa.sigfig.com',
  prod: 'https://EagleProsperPortfoliosClient.EagleStrategies.com',
  _: 'https://dw-iqa1-nyl.qa.sigfig.com',
});

export const defaultPartnerConfigs: PartnerConfig = {
  contentOptions: { locale: Locale.en_us, partner: Partner.NYL, product: Product.OpsDashboard },
  defaultProductType: ManagedProductType.DIGITAL_ADVICE_PRO,
  featureFlags: {
    ...defaultCoreConfig.featureFlags,
    allowEditManuallyLinkedBankAccounts: true,
    allowPlaidReAuthentication: true,
    isDocusignRequiredForFinancialAccountLinkageInRCE: true,
    isManualLinkageForBankAccountSupported: true,
    isPlaidLinkageForBankAccountSupported: true,
    isSuspendedAccountStateSupported: false,
    msiEnabled: false,
    repCodeIdentifierName: 'NYL_REP_CODE',
    showDeleteButtonOnAccountDetailPage: false,
    showWarningForNonVerifiedFinancialAccountLinkages: true,
  },
  components: {
    ...defaultCoreConfig.components,
    sfAccountDetails: {
      ...defaultCoreConfig.components.sfAccountDetails,
      enableViewPerformanceReport: true,
      showActualAllocationInDonut: false,
      showClosedAccountsInDropdown: true,
      showPortfolioNameInAssetAllocation: true,
    },
    sfAccountProfile: {
      ...defaultCoreConfig.components.sfAccountProfile,
      showSecondaryCTA: true,
    },
    sfAccountSummary: {
      ...defaultCoreConfig.components.sfAccountSummary,
      fallBackToStagedModelPortfolio: true,
    },
    sfAddFunds: {
      disableDateAfterXDays: 365,
      disableDateCurrentYearForRetirementAccounts: true,
      disableDateMarketHolidays: true,
      disableDateWeekends: true,
    },
    sfClientDocuments: {
      ...defaultCoreConfig.components.sfClientDocuments,
      downloadDocumentBaseUrl: `${getBaseUrl()}${getSymphonyPath()}`,
    },
    sfCloseAccount: {
      showEndEngagementTaxWithholdingSteps: false,
      showShouldSellAssets: true,
    },
    sfDownloadQPR: {
      ...defaultCoreConfig.components.sfDownloadQPR,
      allocationDetailsColumns: [
        AssetAllocationTableColumns.AssetClass,
        AssetAllocationTableColumns.TargetAllocation,
        AssetAllocationTableColumns.ActualAllocation,
        AssetAllocationTableColumns.Value,
      ],
      displayAllocationBar: false,
    },
    sfFaPlaidFlow: {
      loginBaseUrl: clientRceBaseUrl + '/plaid',
      otpExpiryTimeInMinutes: 1440,
      showProductImage: true,
    },
    sfLinkBankAccount: {
      ...defaultCoreConfig.components.sfLinkBankAccount,
      showNameOnAccountField: false,
      showBankNameField: false,
      showConfirmAccountNumberField: true,
      showConfirmRoutingNumberField: true,
    },
    sfFunding: {
      ...defaultCoreConfig.components.sfFunding,
      allowFundingToSetFundingValue: true,
      ageDataPoint: 'data-point:da-pro-age:integer',
      amountValidationBasedOnAccountTypes: [
        {
          accountType: FinancialAccountType.TRADITIONAL_IRA,
          ageMidpoint: 50,
          currentYear: {
            valueGreaterThanAge: 8000,
            valueLesserThanAge: 7000,
          },
          previousYear: {
            valueGreaterThanAge: 7500,
            valueLesserThanAge: 6500,
          },
        },
        {
          accountType: FinancialAccountType.ROLLOVER_IRA,
          ageMidpoint: 50,
          currentYear: {
            valueGreaterThanAge: 8000,
            valueLesserThanAge: 7000,
          },
          previousYear: {
            valueGreaterThanAge: 7500,
            valueLesserThanAge: 6500,
          },
        },
        {
          accountType: FinancialAccountType.ROTH_IRA,
          ageMidpoint: 50,
          currentYear: {
            valueGreaterThanAge: 8000,
            valueLesserThanAge: 7000,
          },
          previousYear: {
            valueGreaterThanAge: 7500,
            valueLesserThanAge: 6500,
          },
        },
      ],
      brokerageAccountNumberValidationRegex: /[^a-z0-9-]/i,
      onlyEnableLikeToLikeJournaling: true,
      isFundLaterReasonEnabled: false,
    },
    sfInvestmentRestrictions: {
      maximumRestrictions: null,
    },
    sfOpsDashboard: {
      enableCommentDeletion: false,
      showMoreInfoOnBankVerificationModal: false,
      showProductName: false,
    },
    sfPaperwork: {
      ...defaultCoreConfig.components.sfPaperwork,
      trustedContact: {
        allowSpecialCharacters: true,
        optionalCity: true,
        optionalEmailAddress: true,
        optionalState: true,
        optionalStreetAddress: true,
        optionalZipCode: true,
      },
      isPerStirpesEnabled: true,
    },
    sfWithdrawFunds: {
      disableDateAfterMaxWithdrawalDate: true,
      disableDateAfterXDays: 365,
      disableDateCurrentYearForRetirementAccounts: true,
      disableDateMarketHolidays: true,
      disableDateTillAsSoonAsPossibleDate: true,
      disableDateWeekends: true,
      isDocusignRequiredForRetirementWithdrawals: false,
      showHardBlockForLowerLimit: false,
    },
  },
};

export default {
  contentstack: {
    environment: getConfigValue({
      iqa1: 'dev',
      prod: 'nyl_prod',
      sqa1: 'nyl_sqa1',
      sqa2: 'nyl_sqa2',
      stg: 'nyl_prod',
      _: 'dev',
    }),
    deliveryToken: getConfigValue({
      iqa1: 'csaffbef3139b4b24aee2cc24c',
      prod: 'csca624325f9c1530b17380ef1',
      sqa1: 'cs77ab7edaf71c2bf8d09202c4',
      sqa2: 'cscb36302991d795f8b3efbde2',
      stg: 'csca624325f9c1530b17380ef1',
      _: 'csaffbef3139b4b24aee2cc24c',
    }),
  },
  auth0: {
    clientId: getConfigValue({
      iqa1: 'QYqmzlzT3L79HXJBFnHQkBYFSVNsXDJS',
      sqa1: 'vQopg2sykftwzhEIoCZFaZOC1aZ7trsy',
      sqa2: '0NlSj9SMUIATXWOo45OnKEW4pMUq0WhC',
      stg: 'ZWWzkJAhLHIhq4ddz7DR35KPYUFpHi5N',
      prod: '78wS3Ph1wM9CbXSq8sRut8bcN8v0TWzN',
      _: 'QYqmzlzT3L79HXJBFnHQkBYFSVNsXDJS',
    }),
    domain: getConfigValue({
      iqa1: 'nyl-iqa.us.auth0.com',
      sqa1: 'nyl-sqa1.us.auth0.com',
      sqa2: 'nyl-sqa2.us.auth0.com',
      stg: 'nyl-stg.us.auth0.com',
      prod: 'nyl-prod.us.auth0.com',
      _: 'nyl-iqa.us.auth0.com',
    }),
    logoutUrl: getConfigValue({
      iqa1: iqa1Url,
      sqa1: sqa1Url,
      sqa2: sqa2Url,
      stg: stgUrl,
      prod: prodUrl,
      _: iqa1Url,
    }),
  },
  posthog: {
    token: getConfigValue({
      prod: 'phc_QTYlV8sjyedwhJzMlZ6hpqTO4MoucyHpjOEDQpltO0s',
      _: 'phc_ejNgsESdrWuFpgLCf4GTqEs6OUgcFEkNOGTrh80K14t',
    }),
  },
};
