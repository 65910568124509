export { default as BoldFont } from './fonts/tahoma-bold.ttf';
export { default as NormalFont } from './fonts/tahoma.ttf';
import { createBreakpoints } from '@mui/system';

import { alpha, createDefaultTheme, ThemeOptions } from '@sigfig/digital-wealth-core';

const palette = {
  action: {
    background: '#EBF7F5',
    disabled: '#868B8D',
    disabledBackground: '#E9E8E8',
  },
  error: { light: '#F88078', main: '#CC0000' },
  info: { light: '#64B6F7', main: '#1BB3BC' },
  primary: {
    main: '#0C3C52',
    white: '#FFFFFF',
    background: alpha('#0C3C52', 0.08),
    selected: alpha('#0C3C52', 0.08),
  },
  secondary: { main: '#0C3C52' },
  success: { light: '#7BC67E', main: '#4CAF50' },
  text: {
    primary: '#434343',
    secondary: '#666666',
    disabled: '#BFBFBF',
  },
  warning: { main: '#E97300' },
  background: {
    default: '#FAFAFA',
    light: '#FEECED',
  },
  other: {
    neutral: '#F0F0F0',
  },
};

const components: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: {
      disableElevation: false,
    },
    styleOverrides: {
      root: {
        borderRadius: '4px',
        textTransform: 'none',
        '&.MuiButton-contained': {
          boxShadow: 'none',
          '&:hover': {
            boxShadow:
              '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);',
          },
        },
      },
      sizeLarge: {
        fontSize: '16px',
      },
      sizeMedium: {
        fontSize: '14px',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: '#666666',
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      button: {
        fontSize: '14px',
      },
    },
  },
  MuiPagination: {
    defaultProps: {
      color: 'standard',
      size: 'large',
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        '.MuiButton-root': {
          color: 'white',
        },
      },
    },
  },
};

export enum FontFamilies {
  Georgia = 'Georgia',
  Tahoma = 'Tahoma',
  TahomaBold = 'Tahoma Bold',
}

const breakpoints = createBreakpoints({});
const typography: ThemeOptions['typography'] = {
  h1: {
    fontFamily: FontFamilies.Georgia,
    fontSize: 36,
    fontWeight: 400,
    letterSpacing: '-0.25px',
    lineHeight: '60px',
  },
  h2: {
    fontFamily: FontFamilies.Georgia,
    fontSize: 26,
    fontWeight: 400,
    lineHeight: '60px',
    [breakpoints.down('md')]: {
      fontSize: 36,
      lineHeight: '42px',
    },
  },
  h3: {
    fontFamily: FontFamilies.Georgia,
    fontSize: 22,
    fontWeight: 400,
    lineHeight: '44px',
  },
  h4: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 22,
    fontWeight: 400,
    lineHeight: '40px',
  },
  h5: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '34px',
  },
  h6: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '32px',
  },
  subtitle1: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '24px',
  },
  subtitle2: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
  },
  body1: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 16,
    fontWeight: 400,
    fontWeightBold: 700,
    lineHeight: '24px',
  },
  body2: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
  },
  button: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0,
  },
  caption: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
  },
  overline: {
    fontFamily: FontFamilies.Tahoma,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '28px',
  },
};

const sfComponents: ThemeOptions = {
  sfAccount: {
    styles: {},
    typographyVariants: {
      portfolioName: 'subtitle2',
      balance: 'subtitle2',
      ytd: 'subtitle2',
    },
  },
  sfAccountDetails: {
    styles: {},
    typographyVariants: {
      headerTitle: 'h4',
    },
  },
  sfAccountInfo: {
    styles: {
      accountProgramLink: {
        color: 'info.dark',
        textDecoration: 'underline',
      },
      subHeading: {
        color: 'text.secondary',
      },
    },
  },
  sfAccountList: {
    styles: {},
    typographyVariants: {
      heading: 'h5',
    },
  },
  sfAccountTotal: {
    styles: {},
    typographyVariants: {
      heading: 'h5',
      balance: 'h5',
    },
  },
  sfModal: {
    root: {},
    styles: {
      dialogActions: {
        '.MuiButton-root': {
          borderRadius: '4px',
        },
      },
    },
    typographyVariants: {
      title: 'h6',
    },
  },
  sfOpsDashboard: {
    styles: {
      opsHeaderLogo: {
        height: 'auto',
        width: 'auto',
        marginBottom: '-8px',
        marginTop: '-8px',
      },
      tabsContainer: {
        background: '#363F50',
      },
      chip: {
        label: {
          completed: { backgroundColor: 'success.main', color: 'text.primary', fontWeight: 700 },
          approved: { backgroundColor: 'success.light', color: 'text.primary', fontWeight: 700 },
          succeeded: { backgroundColor: 'success.light', color: 'text.primary', fontWeight: 700 },
          default: { backgroundColor: 'other.divider', fontWeight: 700 },
          active: { backgroundColor: 'warning.light', color: 'text.primary', fontWeight: 700 },
          declined: { backgroundColor: 'error.light', color: 'text.primary', fontWeight: 700 },
          rejected: { backgroundColor: 'error.light', color: 'text.primary', fontWeight: 700 },
          failed: { backgroundColor: 'error.light', color: 'text.primary', fontWeight: 700 },
          cancelled: { backgroundColor: 'error.light', color: 'text.primary', fontWeight: 700 },
        },
        root: {},
      },
      tabs: {
        padding: 0,
      },
      tab: {
        '&.Mui-selected': {
          backgroundColor: palette.primary.background,
          color: palette.primary.main,
        },
      },
    },
  },
  sfStatusButton: {
    styles: {
      label: {
        cancelled: { backgroundColor: 'error.light', color: 'text.primary', fontWeight: 700 },
        completed: { backgroundColor: 'success.light', color: 'text.primary', fontWeight: 700 },
        default: { backgroundColor: 'other.neutral', color: 'text.primary', fontWeight: 700 },
        in_progress: { backgroundColor: 'info.light', color: 'text.primary', fontWeight: 700 },
        pending: { backgroundColor: 'other.neutral', color: 'text.primary', fontWeight: 700 },
        unrecognized: { backgroundColor: 'error.main', color: 'text.primary', fontWeight: 700 },
        below_minimum: { backgroundColor: 'info.light', color: 'text.primary', fontWeight: 700 },
        funded: { backgroundColor: 'success.light', color: 'text.primary', fontWeight: 700 },
        needs_review: { backgroundColor: 'error.main', color: 'text.primary', fontWeight: 700 },
      },
      root: {},
    },
  },
  sfPoweredByFooter: {
    styles: {
      partnerLogo: {
        height: '18px',
        width: '117px',
      },
    },
  },
  sfPerformanceChart: {
    styles: {
      linkDecoration: 'none',
      lineColor: palette.text.secondary,
    },
  },
};

export const defaultTheme = createDefaultTheme({
  components,
  palette,
  typography,
  ...sfComponents,
});
