import { loader } from 'graphql.macro';

import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import {
  GetAwaitingClientSignContent,
  GetAwaitingClientSignContentVariables,
} from './__generated__/GetAwaitingClientSignContent';
import { GetDocusignContent, GetDocusignContentVariables } from './__generated__/GetDocusignContent';

const docusignQuery = loader('./GetDocusignContent.gql');

const awaitingClientSignQuery = loader('./GetAwaitingClientSignContent.gql');

export const useGetAwaitingClientSignContent = (
  options?: QueryHookOptions<GetAwaitingClientSignContent, GetAwaitingClientSignContentVariables>,
): QueryResult<GetAwaitingClientSignContent, GetAwaitingClientSignContentVariables> => {
  return useContentstackQuery(awaitingClientSignQuery, options);
};

export const useGetDocusignContent = (
  options?: QueryHookOptions<GetDocusignContent, GetDocusignContentVariables>,
): QueryResult<GetDocusignContent, GetDocusignContentVariables> => {
  return useContentstackQuery(docusignQuery, options);
};
